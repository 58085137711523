$(document).ready(function () { /* Fat arrow doesn't compile */
	/* Start here */

	var form = $('.questions form');

	var count = form.find('p[data-count]').data('count');
	var inputList;

	form.find('a').click(function (e) {
		e.preventDefault();

		if ($(this).hasClass('prev') && count > 1) {
			$(form.find('.questions-details').get(count-1)).removeClass('error');
			count--;
		} else if ($(this).hasClass('next') && count < form.find('.questions-details').length) {
			inputVerifier()
		}
		form.find('.questions-details.active').removeClass('active');
		$(form.find('.questions-details').get(count-1)).addClass('active');
		form.find('p[data-count] span').text(count);
		form.find('p[data-count]').attr('data-count', count);

		return false;
	});

	form.find('button').click(function (event) {
		inputVerifier(event);
	});

	function inputVerifier() {
		inputList = $(form.find('.questions-details').get(count-1)).find('[required]');
		var valid = (inputList.length > 0) ? false : true;

		inputList.each(function () {
			if (($(this).attr('type') == 'radio' && $(this).prop('checked'))
				|| ($(this).attr('type') != 'radio' && $(this).val().length > 0)) {
				valid = true;
			}
		});

		if (!valid) {
			$(form.find('.questions-details').get(count-1)).addClass('error');
			event.preventDefault();
		} else {
			$(form.find('.questions-details').get(count-1)).removeClass('error');
			count++;
		}
	}
});
